
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

jQuery = window.$ = window.jQuery = require('jquery');

require('bootstrap');

require('metismenu');
require('jquery-slimscroll');
/*require('pace-progress'); - naudojam truputi pakeista (buga pataisyta su deprecated funkcionalumu) */
require('./vendor/pace-progress/pace.js');
require('./vendor/jqgrid/src/i18n/grid.locale-lt.js');
window.jqGrid = require('./vendor/jqgrid/src/jquery.jqGrid.js');
require('./vendor/jqgrid/plugins/jquery.contextmenu.js');
require('sweetalert');
require('select2/dist/js/select2.full.js');
require('bootstrap-datepicker');
require('icheck');
require('typeahead.js');
require('bootstrap-tagsinput');
Bloodhound = require('bloodhound-js');

window.toastr = require('toastr');

Echo = require('laravel-echo');
window.Pusher = require('pusher-js');
window.ionRangeSlider = require('ion-rangeslider');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: Laravel.pusherKey,
    authEndpoint: Laravel.authEndpoint,
    cluster: 'eu',
    encrypted: true
});


$(document).ready(function () {

    // iraso lockinimas ir lock time pratesimas kas minute
    function extendLockModelTime(idValue, modelValue, extendUrl, url) {
        if (extendUrl !== undefined) {
            setTimeout(function () {
                $.post(extendUrl, {id: idValue, model: modelValue}, function (data) {
                    if (!data.status) {
                        window.location.href = url;
                    }
                });
                extendLockModelTime(idValue, modelValue, extendUrl);
            }, 1000 * 60);
        }
    }

    function daysInMonth(year, month) {
        var d = new Date(year, month, 0);
        return d.getDate();
    }

    window.formatDate = function(str) {
        var dateStr = '';
        var year;
        var yyyy;
        var mm;
        var dd;
        var sep = '-';

        str = str.replace(',', sep);
        str = str.replace(',', sep);
        str = str.replace('.', sep);
        str = str.replace('.', sep);

        point = str.indexOf(sep,0);
        if(point === -1){
            if(str.length === 6) {
                var yearNow = new Date().getFullYear().toString().substr(-2);
                var yearNowEntered = str.substr(0, 2);
                if (yearNow >= yearNowEntered) {
                    str = '20' + str;
                } else {
                    str = '19' + str;
                }
            }
            var tmp = str.split('');
            if((tmp[4] !== sep)&&(tmp[7] !== sep))
                str = '' + tmp[0] + tmp[1] + tmp[2] + tmp[3] + sep + tmp[4] + tmp[5] + sep + tmp[6] + tmp[7];
        }

        if(( ( year = 4 ) && ( str.charAt(4) === sep ) && ( str.charAt(7) === sep ) && ( !isNaN(yyyy = str.substr(0,4)*1) ) && ( !isNaN(mm = str.substr(5,2)*1) ) && ( !isNaN(dd = str.substr(8,2)*1) ) || ( year = 2 ) && ( str.charAt(2) === sep ) && ( str.charAt(5) === sep ) && ( !isNaN(yyyy = str.substr(0,2)*1) ) && ( !isNaN(mm = str.substr(3,2)*1) ) && ( !isNaN(dd = str.substr(6,2)*1) ) ) && ( mm > 0 ) && ( mm < 13 ) && ( dd > 0 ) && ( dd <= daysInMonth(yyyy, mm) )	) {
            if ( year === 2 ) { ( yyyy > 50 ) ? yyyy += 1900 : yyyy += 2000; }
            dateStr = yyyy + sep + (( mm < 10 )?'0':'') + mm + sep + (( dd < 10 )?'0':'') + dd;
        }else if(( ( year = 4 ) && ( str.charAt(4) === sep ) && ( str.charAt(7) === sep ) && ( !isNaN(yyyy = str.substr(0,4)*1) ) && ( !isNaN(mm = str.substr(5,2)*1) ) && ( !isNaN(dd = str.substr(8,2)*1) ) || ( year = 2 ) && ( str.charAt(2) === sep ) && ( str.charAt(5) === sep ) && ( !isNaN(yyyy = str.substr(0,2)*1) ) && ( !isNaN(mm = str.substr(3,2)*1) ) && ( !isNaN(dd = str.substr(6,2)*1) ) ) && ( mm > 0 ) && ( mm < 13 ) && ( dd > 0 ) && ( dd > daysInMonth(yyyy, mm))	) {
            dd = daysInMonth(yyyy, mm);
            if ( year === 2 ) { ( yyyy > 50 ) ? yyyy += 1900 : yyyy += 2000; }
            dateStr = yyyy + sep + (( mm < 10 )?'0':'') + mm + sep + (( dd < 10 )?'0':'') + dd;
        }
        return dateStr;
    };

    window.formatTime = function(str) {
        // Checks if time is in HH:MM:SS
        // The seconds are optional.
        if (str == "") {
            return '00:00'
        }
        str=str.replace(' ', ':');
        str=str.replace(':', ':');
        str=str.replace(',', ':');
        str=str.replace(';', ':');
        str=str.replace('-', ':');
        str=str.replace('.', ':');

        if ((str.indexOf(":")==-1)&&(str.length>3)){
            tmp = str.split('');
            str = tmp[0] + tmp[1] + ':' + tmp[2] + tmp[3];
        }
        str = str.substr(0,5);

        var tmp = str.split(":",2);
        if ((tmp[0]!='undefined')&&(tmp[0] != null)&&(tmp[0] != '')) {
            hour = tmp[0];
        } else {
            hour = '00';
        }
        if ((tmp[1]!='undefined')&&(tmp[1] != null)&&(tmp[1] != '')) {
            minute = tmp[1];
        } else {
            minute = '00';
        }

        if (hour.length == 1){
            hour = '0' + hour;
        }
        if (minute.length == 1){
            minute = '0' + minute;
        }

        if (hour < 0) {
            hour = '00';
        }
        if (hour > 23) {
            hour = '23';
        }
        if (minute<0) {
            minute = '00';
        }
        if (minute > 59) {
            minute = '59';
        }

        return hour + ':' + minute;
    };

    window.formatDateTime = function(str) {
        var date = '';
        var time = '';
        var tmp = str.split(" ",2);
        if ((tmp[0]!='undefined')&&(tmp[0] != null)&&(tmp[0] != '')) {
            date = formatDate(tmp[0]);
        } else {
            return '';
        }

        if (date == ''){
            return date;
        }

        if ((tmp[1]!='undefined')&&(tmp[1] != null)&&(tmp[1] != '')) {
            time = formatTime(tmp[1]);
        } else {
            return date + ' 00:00';
        }
        return date + ' ' + time;
    };

    $('.asis-unlock-and-close-button').click(function () {
        unlockUrl = $(this).data('unlock-url');
        url = $(this).data('url');
        idValue = $(this).data('id');
        modelValue = $(this).data('model');
        $.post(unlockUrl, {id: idValue, model: modelValue}, function () {
            window.location.href = url;
        });
    });
    $('.asis-unlock-and-close-button').each(function () {
        extendUrl = $(this).data('extend-url');
        idValue = $(this).data('id');
        modelValue = $(this).data('model');
        url = $(this).data('url');
        extendLockModelTime(idValue, modelValue, extendUrl, url);
    });

    // DataTable redagavimo formos submitinimas kai bandopm atidaryti detalia forma
    window.saveFormThenOpenDetail = function(element, nextAction, id) {
        var form = element.closest('form');
        var input = form.find('#_ext_action');
        if (input.length === 0) {
            form.append('<input type="hidden" id="_ext_action" name="_ext_action" value="' + nextAction + '" />');
        } else {
            input[0].val(nextAction)
        }
        if (id != '') {
            var input2 = form.find('#_ext_id');
            if (input2.length === 0) {
                form.append('<input type="hidden" id="_ext_id" name="_ext_id" value="' + id + '" />');
            } else {
                input2[0].val(id)
            }
        }
        form.submit();
    }

    function submitAuditForm(id, model, csrfToken, route) {
        if (Number.isInteger(Number(id))) {
            $('body').append('<form id="model-audit-form"></form>');
            var form = $('#model-audit-form');
            form.attr('action', route);
            form.attr('method', 'POST');
            form.append('<input type="hidden" name="id" value="' + id + '">');
            form.append('<input type="hidden" name="model" value="' + model + '">');
            form.append('<input type="hidden" name="_token" value="' + csrfToken + '">');
            form.submit();
        }
    }

    window.bindModelAuditLink = function(linkClass, csrfToken, route) {
        $("." + linkClass).click(function(){
            var model = $(this).data("eilika-model");
            var id = $(this).data("eilika-id");
            submitAuditForm(id, model, csrfToken, route);
            return false;
        });
    };

    window.bindRuntimeModelAuditLink = function(linkClass, id, model, csrfToken, route) {
        $("." + linkClass).click(function(){
            submitAuditForm(id, model, csrfToken, route);
            return false;
        });
    };
});